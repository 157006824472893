import request from '@/utils/request';

/**
 * 获取文章分类
 * @param {Object} data
 */
export function getCategoryList(data) {
  return request({
    url: '/article/getCategoryList',
    method: 'post',
    data
  });
}

/**
 * 保存或修改文章信息
 * @param {Object} data
 */
export function saveArticle(data) {
  return request({
    url: '/article/save',
    method: 'post',
    data
  });
}

/**
 * 查询文章列表
 * @param {Object} data
 */
export function getArticleList(data) {
  return request({
    url: '/article/getList',
    method: 'post',
    data
  });
}

/**
 * 删除操作
 * @param {Object} data
 */
export function delArticle(data) {
  return request({
    url: '/article/del',
    method: 'post',
    data
  });
}

/**
 * 置顶操作
 * @param {Object} data
 */
export function topArticle(data) {
  return request({
    url: '/article/top',
    method: 'post',
    data
  });
}

/**
 * 是否推送（1-是 0-否）
 * @param {Object} data
 */
export function updateStatus(data) {
  return request({
    url: '/article/updateStatus',
    method: 'post',
    data
  });
}
