<template>
  <div class="container">
    <div class="handle-box" style="float: left;">
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
      <el-button type="success" icon="el-icon-plus" @click="onAdd">
        添加
      </el-button>
      <el-button type="danger" icon="el-icon-delete" @click="delAll">
        批量删除
      </el-button>
    </div>
    <div style="float: right;">
      <el-form
        ref="queryForm"
        :model="query"
        :inline="true"
        label-width="120px"
      >
        <el-form-item prop="title">
          <el-input
            v-model="query.title"
            placeholder="请输入文章标题"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="author">
          <el-input
            v-model="query.author"
            placeholder="请输入作者"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="category">
          <el-select
            v-model="query.category"
            placeholder="请选择文章分类"
            clearable
          >
            <el-option
              v-for="(item, index) in categoryList"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">
            搜索
          </el-button>
          <el-button icon="el-icon-reset" @click="resetForm('queryForm')">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="clear: both;"></div>
    <!--数据表格-->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
      @row-dblclick="onEdit"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column label="文章标题" prop="title" />
      <el-table-column label="文章分类" prop="category" />
      <el-table-column
        label="创建时间"
        prop="createTime"
        width="180"
        sortable
      />
      <el-table-column label="作者" prop="author" sortable />
      <el-table-column label="点赞数" prop="praise" />
      <el-table-column label="是否推送" prop="status">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
            @change="changeSwitch($event, scope.row, scope.$index)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="是否置顶" prop="isTop">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isTop"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
            @change="changeSwitch($event, scope.row, scope.$index)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280" align="center">
        <template slot-scope="scope">
          <el-button type="success" size="small" @click="onEdit(scope.row)">
            编辑
          </el-button>
          <el-button type="danger" size="small" @click="onDel(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>

    <!-- 编辑文章 -->
    <el-dialog
      title="编辑文章"
      width="50%"
      :visible.sync="selectDlgShow"
      fullscreen
      center
    >
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="ruleForm" :model="form" label-width="120px">
          <el-form-item label="文章标题" prop="title" required>
            <el-input
              v-model="form.title"
              type="text"
              placeholder="请输入文章标题"
              clearable
            />
          </el-form-item>
          <el-form-item label="文章分类" prop="category" required>
            <el-select
              v-model="form.category"
              placeholder="请选择文章分类"
              clearable
            >
              <el-option
                v-for="(item, index) in categoryList"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文章内容" prop="content" required>
            <quill-editor
              ref="editor"
              v-model="form.content"
              :options="editorOption"
              placeholder="请输入文章内容"
            ></quill-editor>
          </el-form-item>
          <el-form-item label="是否置顶" prop="isTop" required>
            <el-radio v-model="form.isTop" :label="1">是</el-radio>
            <el-radio v-model="form.isTop" :label="0">否</el-radio>
          </el-form-item>
          <el-form-item label="是否推送" prop="status" required>
            <el-radio v-model="form.status" :label="1">是</el-radio>
            <el-radio v-model="form.status" :label="0">否</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="selectDlgShow = false">关 闭</el-button>
        <el-button plain @click="resetForm('ruleForm')">重 置</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">
          保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCategoryList,
  saveArticle,
  getArticleList,
  delArticle,
  topArticle,
  updateStatus
} from '@/api/admin/article';
import { Message, MessageBox } from 'element-ui';
import { constants } from 'fs';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
import quillConfig from '@/assets/js/quill-config';

export default {
  name: 'Acticle',
  components: {
    quillEditor
  },
  data() {
    return {
      userId: sessionStorage.getItem('userId'),
      form: {
        userid: '',
        id: '',
        title: '',
        category: '',
        content: '',
        isTop: 0,
        status: 1
      },
      query: {
        title: '',
        category: '',
        author: ''
      },
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
        title: '',
        category: '',
        author: ''
      },
      categoryList: [],
      list: [],
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: [],
      editorOption: {
        modules: {
          toolbar: quillConfig.toolOptions
        }
      }
    };
  },
  created() {
    this.fetchData();
    this.loadCategoryList();
  },
  methods: {
    loadCategoryList() {
      getCategoryList().then(resp => {
        if (resp.code === 200) {
          this.categoryList = resp.data;
        }
      });
    },
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      this.pageInfo.title = this.query.title;
      this.pageInfo.category = this.query.category;
      this.pageInfo.author = this.query.author;
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
    },
    //添加
    onAdd() {
      this.form = {
        userid: this.userId,
        id: '',
        title: '',
        category: '',
        content: '',
        isTop: 0,
        status: 1
      };
      this.selectDlgShow = true;
    },
    //编辑
    onEdit(row) {
      this.form = {
        userid: this.userId,
        id: row.id,
        title: row.title,
        category: row.category,
        content: row.content,
        isTop: row.isTop,
        status: row.status
      };
      this.selectDlgShow = true;
    },
    //查询列表
    fetchData() {
      this.listLoading = true;
      getArticleList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    //批量删除
    delAll() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请至少选择一条数据');
        return;
      }
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delArticle({ ids: ids })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //删除
    onDel(data) {
      this.$confirm('您确定要删除此条数据吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delArticle({ id: data.id })
          .then(resp => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //验证表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
    //保存与修改
    save() {
      this.dlgLoading = true;
      saveArticle(this.form)
        .then(resp => {
          if (resp.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.fetchData();
          } else {
            this.$message.error('保存失败');
          }
          this.selectDlgShow = false;
          this.dlgLoading = false;
        })
        .catch(() => {
          this.dlgLoading = false;
        });
    },
    //上下架
    onStatus(row, status) {
      //   updateInfoStatus({ id: row.id, status: status }).then(resp => {
      //     if (resp.code == 200) {
      //       this.$message({
      //         message: '操作成功',
      //         type: 'success'
      //       });
      //       this.onRefresh();
      //     } else {
      //       this.$message.error('操作失败');
      //     }
      //   });
    },
    //开关切换
    changeSwitch(data, b, index) {
      saveArticle(b).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: '状态修改成功',
            type: 'success'
          });
        } else {
          this.$message.error(resp.msg);
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.el-dropdown {
  margin-left: 10px;
}
</style>
